[data-theme='sa-2025'] {

    /* colour */

    --primary: #3C1A52;
    --primary-1: #3C1A52;
    --primary-2: #6E439A;
    --primary-2-dark: #5a3182;
    --primary-3: #9972AB;
    
    --secondary: #00744B;
    --secondary-dark: #00442c;
    
    --accent: #DFA46E;
    --accent-dark: #b98350;
    --accent-2: rgb(110, 78, 45);

    --neutral-01: #f6f6f6;
    --neutral-02: #ececec;


    --bg-global: rgb(255, 255, 255);

    --default-text-color: rgb(71, 71, 71);
    --default-heading-color: var(--primary);

    --wash: #ebebe3;
    --light: #DFA46E;
    --medium: #00744B;
    --dark: #00442c;

    --error: rgb(255, 68, 0);
    --success: rgb(121, 173, 0);
    --warning: rgb(255, 166, 0);


    /* spacing */

    --container-max-width: 100rem;
    --container-adjacent-spacing-factor: 0.5;

    --unit-xxs: .5rem;
    --unit-xs: 1rem;
    --unit-sm: 2rem;
    --unit-md: 4rem;
    --unit-lg: 6rem;
    --unit-xl: 8rem;


    /* type */

    --default-font-family: 'Roboto', sans-serif;
    --default-font-weight: 400;
    --default-font-size: 1rem;
    --default-line-height: 1.4;

    --default-heading-line-height: 1.1;
    --default-heading-weight: 600;


    /* global UI styling */

    --default-radius: 0;
    --default-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    --default-shadow-size: 20px;
    --default-shadow-opacity: 0.2;

    --default-button-radius: 4rem;

    --gap-divider-color: currentColor;

    /* header */

    --header-height: 12.5rem;
    --header-height-compact: 6.875rem;
    --header-height-mobile: 8.5rem;
    --header-height-compact-mobile: 8.5rem;

}



/* GLOBAL theme colors */


[data-theme='sa-2025'] .c-primary { color: var(--primary); }
[data-theme='sa-2025'] .c-primary-2 { color: var(--primary-2); }
[data-theme='sa-2025'] .c-primary-3 { color: var(--primary-3); }
[data-theme='sa-2025'] .c-secondary { color: var(--secondary); }
[data-theme='sa-2025'] .c-accent { color: var(--accent); }

[data-theme='sa-2025'] .c-p { color: var(--default-text-color); }
[data-theme='sa-2025'] .c-h { color: var(--default-heading-color); }

[data-theme='sa-2025'] .c-black { color: black; }
[data-theme='sa-2025'] .c-white { color: white; }

[data-theme='sa-2025'] .c-light { color: var(--light) }
[data-theme='sa-2025'] .c-medium { color: var(--medium) }


/* GLOBAL theme backgrounds */

[data-theme='sa-2025'] body { background-color: white }

[data-theme='sa-2025'] .bg-global { background-color: var(--bg-global); }

[data-theme='sa-2025'] .bg-primary { background-color: var(--primary); }
[data-theme='sa-2025'] .bg-primary-2 { background-color: var(--primary-2); }
[data-theme='sa-2025'] .bg-primary-2-dark { background-color: var(--primary-2-dark); }
[data-theme='sa-2025'] .bg-secondary { background-color: var(--secondary); }
[data-theme='sa-2025'] .bg-accent { background-color: var(--accent); }
[data-theme='sa-2025'] .bg-accent-2 { background-color: var(--accent-2); }

[data-theme='sa-2025'] .bg-wash { background-color: var(--neutral-01);}
[data-theme='sa-2025'] .bg-light { background-color: var(--neutral-02);}
[data-theme='sa-2025'] .bg-medium { background-color: var(--medium);}
[data-theme='sa-2025'] .bg-dark { background-color: var(--dark);}


[data-theme='sa-2025'] .bg-neutral-01 { background-color: var(--neutral-01);}
[data-theme='sa-2025'] .bg-neutral-02 { background-color: var(--neutral-02);}




[data-theme='sa-2025'] .bg-theme { 
    background-color: var(--secondary-dark);
    background-image: url('/assets/theme/sa-2025/fhs-pattern-green.png');
    background-size: 60vw;
    background-position: top center;

    @media(max-width:600px) {
        background-size: 250%;
    }
}
[data-theme='sa-2025'] .bg-theme-primary { 
    background-color: var(--primary);
    background-image: url('/assets/theme/world-2024/fhs-pattern-purple-50.png');
    background-size: 60vw;
    background-position: top center;

    @media(max-width:600px) {
        background-size: 250%;
    }
}

[data-theme='sa-2025'] .bg-pattern-green { 
    background-color: var(--secondary);
    background-image: url('/assets/theme/sa-2025/fhs-pattern-green.png');
    background-size: 100%;
    background-position: top center;

    @media(max-width:600px) {
        background-size: 250%;
    }
}

[data-theme='sa-2025'] .bg-pattern-blue { 
    background-color: white;
    background-image: url('/assets/theme/fhs-pattern-light-blue.png');
    background-size: 60vw;
    background-position: top center;

    @media(max-width:600px) {
        background-size: 250%;
    }
}




/* GLOBAL theme text colors */

[data-theme='sa-2025'] .formatted h1,
[data-theme='sa-2025'] .formatted h2,
[data-theme='sa-2025'] .formatted h3,
[data-theme='sa-2025'] .formatted h4,
[data-theme='sa-2025'] .formatted h5,
[data-theme='sa-2025'] .formatted h6 {
    color: inherit;
}
[data-theme='sa-2025'] {
    color: var(--default-text-color);
}

[data-theme='sa-2025'] .formatted .button {
    /* color: var(--primary); */
    background-color: var(--secondary);
    border-color: var(--secondary);
}
[data-theme='sa-2025'] .formatted .button:hover {
    background-color: white;
    border-color: white;
    color: var(--secondary);
}
[data-theme='sa-2025'] .formatted .button.secondary {
    color: var(--primary);
}
[data-theme='sa-2025'] .formatted .button.secondary:hover {
    background-color: white;
}


[data-theme='sa-2025'] .highlight span {
    color: white;
}

[data-theme='sa-2025'] .swiper-button-prev::after,
[data-theme='sa-2025'] .swiper-button-next::after {
    color: white;
    font-size: 2rem !important;
}
[data-theme='sa-2025'] .swiper-button-prev,
[data-theme='sa-2025'] .swiper-button-next {
    height: 1rem !important;
}

@media (max-width: 900px) {
    [data-theme='sa-2025'] .swiper-button-prev::after,
    [data-theme='sa-2025'] .swiper-button-next::after {
        font-size: 1rem !important;
    }
    [data-theme='sa-2025'] .swiper-button-prev,
    [data-theme='sa-2025'] .swiper-button-next {
        height: 1rem !important;
    }
    [data-theme='sa-2025'] .swiper-button-prev {
        left: 0 !important;
    }
    [data-theme='sa-2025'] .swiper-button-next {
        right: 0 !important;
    }
}



[data-theme='sa-2025'] .slug {
    display: inline-block;
    background-color: var(--primary);
    color: white;
    padding: .5rem 1rem;
    border-radius: 2rem;
}
[data-theme='sa-2025'] .slug.keyline {
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}


/* default */

[data-theme='sa-2025'] .bg-default {
    color: var(--default-text-color);
}
[data-theme='sa-2025'] .bg-default .formatted h1,
[data-theme='sa-2025'] .bg-default .formatted h2,
[data-theme='sa-2025'] .bg-default .formatted h3,
[data-theme='sa-2025'] .bg-default .formatted h4,
[data-theme='sa-2025'] .bg-default .formatted h5,
[data-theme='sa-2025'] .bg-default .formatted h6 {
    color: var(--primary);
}
[data-theme='sa-2025'] .bg-default .formatted .button,
[data-theme='sa-2025'] .bg-default .button {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: white !important;
}
[data-theme='sa-2025'] .bg-default .formatted .button:hover,
[data-theme='sa-2025'] .bg-default .button:hover {
    background-color: var(--secondary-dark);
    border-color: var(--secondary-dark);
}
[data-theme='sa-2025'] .bg-default .button {
    color: var(--secondary);
}

/* white */

[data-theme='sa-2025'] .bg-white {
    color: var(--default-text-color);
}
[data-theme='sa-2025'] .bg-white .formatted h1,
[data-theme='sa-2025'] .bg-white .formatted h2,
[data-theme='sa-2025'] .bg-white .formatted h3,
[data-theme='sa-2025'] .bg-white .formatted h4,
[data-theme='sa-2025'] .bg-white .formatted h5,
[data-theme='sa-2025'] .bg-white .formatted h6 {
    color: var(--primary);
}
[data-theme='sa-2025'] .bg-white .quote {
    color: var(--secondary);
}

/* neutral-02 */

[data-theme='sa-2025'] .bg-neutral-02 {
    color: var(--default-text-color);
}
[data-theme='sa-2025'] .bg-neutral-02 .formatted h1,
[data-theme='sa-2025'] .bg-neutral-02 .formatted h2,
[data-theme='sa-2025'] .bg-neutral-02 .formatted h3,
[data-theme='sa-2025'] .bg-neutral-02 .formatted h4,
[data-theme='sa-2025'] .bg-neutral-02 .formatted h5,
[data-theme='sa-2025'] .bg-neutral-02 .formatted h6 {
    color: var(--primary);
}
[data-theme='sa-2025'] .bg-neutral-02 .quote {
    color: var(--secondary);
}

/* wash */

[data-theme='sa-2025'] .bg-wash {
    color: var(--default-text-color);
}
[data-theme='sa-2025'] .bg-wash .formatted h1,
[data-theme='sa-2025'] .bg-wash .formatted h2,
[data-theme='sa-2025'] .bg-wash .formatted h3,
[data-theme='sa-2025'] .bg-wash .formatted h4,
[data-theme='sa-2025'] .bg-wash .formatted h5,
[data-theme='sa-2025'] .bg-wash .formatted h6 {
    color: var(--primary);
}



/* light */

[data-theme='sa-2025'] .bg-light {
    color: var(--default-text-color);
}
[data-theme='sa-2025'] .bg-light .formatted h1,
[data-theme='sa-2025'] .bg-light .formatted h2,
[data-theme='sa-2025'] .bg-light .formatted h3,
[data-theme='sa-2025'] .bg-light .formatted h4,
[data-theme='sa-2025'] .bg-light .formatted h5,
[data-theme='sa-2025'] .bg-light .formatted h6 {
    color: var(--primary);
}
[data-theme='sa-2025'] .bg-light .formatted .button,
[data-theme='sa-2025'] .bg-light .button {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: white;
}
[data-theme='sa-2025'] .bg-light .formatted .button:hover,
[data-theme='sa-2025'] .bg-light .button:hover {
    background-color: var(--secondary-dark);
    border-color: var(--secondary-dark);
}
[data-theme='sa-2025'] .bg-light .button {
    color: var(--secondary);
}

/* secondary */

[data-theme='sa-2025'] .bg-secondary {
    color: white;
}
[data-theme='sa-2025'] .bg-secondary .formatted h1,
[data-theme='sa-2025'] .bg-secondary .formatted h2,
[data-theme='sa-2025'] .bg-secondary .formatted h3,
[data-theme='sa-2025'] .bg-secondary .formatted h4,
[data-theme='sa-2025'] .bg-secondary .formatted h5,
[data-theme='sa-2025'] .bg-secondary .formatted h6 {
    color: var(--accent);
}
[data-theme='sa-2025'] .bg-secondary .form input[type='submit'] {
    color: var(--secondary);
	background-color: var(--accent);
    border: none;
}
[data-theme='sa-2025'] .bg-secondary .form input[type='submit']:hover {
	background-color: white;
}

/* accent */

[data-theme='sa-2025'] .bg-accent {
    color: white;
}
[data-theme='sa-2025'] .bg-accent .formatted h1,
[data-theme='sa-2025'] .bg-accent .formatted h2,
[data-theme='sa-2025'] .bg-accent .formatted h3,
[data-theme='sa-2025'] .bg-accent .formatted h4,
[data-theme='sa-2025'] .bg-accent .formatted h5,
[data-theme='sa-2025'] .bg-accent .formatted h6 {
    color: white;
}

/* primary */

[data-theme='sa-2025'] .bg-primary {
    color: white;
}

[data-theme='sa-2025'] .bg-primary .formatted h1,
[data-theme='sa-2025'] .bg-primary .formatted h2,
[data-theme='sa-2025'] .bg-primary .formatted h3,
[data-theme='sa-2025'] .bg-primary .formatted h4,
[data-theme='sa-2025'] .bg-primary .formatted h5,
[data-theme='sa-2025'] .bg-primary .formatted h6 {
    color: white;
}

[data-theme='sa-2025'] .bg-primary .form label {
    color: white;
}
[data-theme='sa-2025'] .bg-primary .form input[type='submit'] {
    background-color: var(--accent);
    border-color: var(--accent);
    color: white;
}
[data-theme='sa-2025'] .bg-primary .form input[type='submit']:hover {
    background-color: white;
    border-color: white;
    color: var(--dark);
}




/* medium */

[data-theme='sa-2025'] .bg-medium,
[data-theme='sa-2025'] .bg-medium a {
    color: white;
}
[data-theme='sa-2025'] .bg-medium .formatted h1,
[data-theme='sa-2025'] .bg-medium .formatted h2,
[data-theme='sa-2025'] .bg-medium .formatted h3,
[data-theme='sa-2025'] .bg-medium .formatted h4,
[data-theme='sa-2025'] .bg-medium .formatted h5,
[data-theme='sa-2025'] .bg-medium .formatted h6 {
    color: white;
}
[data-theme='sa-2025'] .bg-medium .button {
    background-color: var(--accent);
    border-color: var(--accent);
    color: var(--secondary);
}
[data-theme='sa-2025'] .bg-medium .button:hover {
    background-color: white;
}


/* dark */

[data-theme='sa-2025'] .bg-dark {
    color: white
}
[data-theme='sa-2025'] .bg-dark .formatted h1,
[data-theme='sa-2025'] .bg-dark .formatted h2,
[data-theme='sa-2025'] .bg-dark .formatted h3,
[data-theme='sa-2025'] .bg-dark .formatted h4,
[data-theme='sa-2025'] .bg-dark .formatted h5,
[data-theme='sa-2025'] .bg-dark .formatted h6 {
    color: var(--secondary);
}
[data-theme='sa-2025'] .bg-dark .formatted .button {
    background-color: var(--accent);
    border-color: var(--accent);
}
[data-theme='sa-2025'] .bg-dark .formatted .button:hover {
    background-color: white;
    border-color: white;
}


/* theme */

[data-theme='sa-2025'] .bg-theme {
    color: white;
}
[data-theme='sa-2025'] .bg-theme .formatted h1,
[data-theme='sa-2025'] .bg-theme .formatted h2,
[data-theme='sa-2025'] .bg-theme .formatted h3,
[data-theme='sa-2025'] .bg-theme .formatted h4,
[data-theme='sa-2025'] .bg-theme .formatted h5,
[data-theme='sa-2025'] .bg-theme .formatted h6 {
    color: white;
}
[data-theme='sa-2025'] .bg-theme .formatted .button {
    background-color: var(--accent);
    border-color: var(--accent);
}
[data-theme='sa-2025'] .bg-theme .formatted .button:hover {
    background-color: white;
    border-color: white;
}


/* FORMS ––––––––––––––––––––––––––––––––––––––––––––––––––– */


[data-theme='sa-2025'] .form input[type='submit'] {
    color: white;
	background-color: var(--secondary);
    border: none;
}

[data-theme='sa-2025'] .form input[type='submit']:hover {
	background-color: var(--secondary-dark);
}


/* HUBSPOT SPECIFIC */

[data-theme='sa-2025'] .hs-form-field {
    display: block;
    margin-bottom: 1.5rem;
}

[data-theme='sa-2025'] .hs-form-field > label {
    font-weight: 600;
    font-size: smaller;
}

[data-theme='sa-2025'] .hs-form-field textarea,
[data-theme='sa-2025'] .hs-form-field input[type='text'],
[data-theme='sa-2025'] .hs-form-field input[type='password'],
[data-theme='sa-2025'] .hs-form-field input[type='tel'],
[data-theme='sa-2025'] .hs-form-field input[type='email'] {
    width: 100% !important;
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 0;
    padding-right: 0;
    color: white;
    font-family: inherit;
    border-bottom-color: var(--accent) !important;
}

[data-theme='sa-2025'] select.hs-input {
    background-color: var(--accent);
    border-color: var(--accent) !important;
    background-image: url('/assets/ui/select-chevron-sa-2025.svg');
}


[data-theme='sa-2025'] footer.footer .hs-form-field textarea,
[data-theme='sa-2025'] footer.footer .hs-form-field input[type='text'],
[data-theme='sa-2025'] footer.footer .hs-form-field input[type='password'],
[data-theme='sa-2025'] footer.footer .hs-form-field input[type='tel'],
[data-theme='sa-2025'] footer.footer .hs-form-field input[type='email'] {
    border-bottom-color: var(--accent) !important;
}


[data-theme='sa-2025'] footer .form input {
    border-bottom-color: var(--accent) !important;
    color: white;
}
[data-theme='sa-2025'] footer .form input:focus {
    border-bottom-color: white !important;
}

[data-theme='sa-2025'] footer .form input[type='submit'] {
    color: white;
	background-color: var(--secondary);
    border: none;
}
[data-theme='sa-2025'] footer .form input[type='submit']:hover {
	background-color: var(--secondary-dark);
}


[data-theme='sa-2025'] .hs_submit, 
[data-theme='sa-2025'] .hs-button, 
[data-theme='sa-2025'] .hs-submit,
[data-theme='sa-2025'] .hs_submit:hover, 
[data-theme='sa-2025'] .hs-button:hover,
[data-theme='sa-2025'] .hs-submit:hover {
    border: none !important;
}

[data-theme='sa-2025'] .hs-error-msgs {
    list-style: none;
    color: var(--error);
    font-weight: 400;
    padding-left: 0;
}

[data-theme='sa-2025'] .hs-error-msg {
    margin-top: .5rem;
    color: var(--error);
    font-weight: 400;
}

[data-theme='sa-2025'] .hs-main-font-element {
    margin-top: .5rem;
    font-weight: 400;
}

[data-theme='sa-2025'] .form-columns-2 {
    max-width: 100% !important;
}


/* chatbot */

[data-theme='sa-2025'] .woot-widget-bubble {
    background: var(--accent) !important;
}